import React from 'react';
import styles from './DisclaimerText.module.scss';

function DisclaimerText() {
  return (
    <div className={styles.DisclaimerText}>
      <strong>Disclaimer:</strong> The information provided in this chat is not a substitute for professional medical advice, diagnosis,
      or treatment. Always seek the advice of your physician or other qualified health provider with any questions you
      may have regarding a medical condition. Never disregard medical advice or delay seeking medical advice because of
      any content or information provided in this Chat. Your use of the App does not constitute or create a
      doctor-patient or other healthcare professional relationship. In case of emergency, call <a href='tel: 911'>911</a> or your local
      emergency services immediately.
    </div>
  );
}

export default DisclaimerText;
