const pushEvent = (obj) => {
    if (obj && window.dataLayer) {
        window.dataLayer.push(obj);
    }
}

const onChatEvent = (event, payload = {}) => {
    const params = new URL(document.location).searchParams;
    payload.parentHostname = params.get("parentHostname");

    pushEvent({
        event: `chat_${event}`,
        ...payload
    });
}

const createAdditionalGMTScript = (code) => {
    const script = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${code}');
    `;
    const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${code}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    appendScriptToHead(script);
    appendNoScriptToHead(noscript);
}

const appendScriptToHead = (content) => {
    const scriptElement = document.createElement('script');
    scriptElement.textContent = content;
    document.head.appendChild(scriptElement);
}

const appendNoScriptToHead = (content) => {
    const noScriptElement = document.createElement('noscript');
    noScriptElement.textContent = content;
    document.body.prepend(noScriptElement);
}

export const dataLayerService = {
    pushEvent,
    onChatEvent,
    createAdditionalGMTScript
};
