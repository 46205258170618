import React from 'react';
import styles from './TermsAndConditionsDialog.module.scss';

function TermsAndConditionsDialog({onCancel, onApprove}) {
    return (
        <div
            className={styles.TermsAndConditionsDialog}
        >
            <div className={styles.content}>
                <h1>Terms of Use</h1>
                <p>These Terms of Use govern your use of the My Ohio Wellness Chatbot by CampusWell (hereinafter “the Chatbot”). By accessing or using the Chatbot you agree to be bound by the Terms of Use in full, therefore review the Terms carefully. If you don't accept these Terms, please don't access or use the Chatbot.</p>
                <h2>1. Changes to the Terms and Functionality</h2>
                <p>We may update these Terms from time to time to be effective immediately upon updating without notice to you in our sole discretion. The continued use of the Chatbot following the posting constitutes your acceptance of the modified Terms of Use.</p>
                <p>In addition, we may modify any part of the Chatbot, including any functionality or feature, temporarily or permanently, with or without notice to you.</p>
                <h2>2. Chatbot Functionality and Services</h2>
                <p>The Chatbot can offer the following information and services:</p>
                <ol>
                <li>Providing evidence-based content on medical, as well as health and wellness topics, such as different types of intelligence, career fits, diseases, treatments and healthy lifestyle;</li>
                <li>Enabling users to access disability, injury, and illness accommodations and services, counseling and therapy services, health services, career services, academic advising, recreational and fitness resources, and library services.</li>
                </ol>
                <h2>3. User Content</h2>
                <p>When providing any type of content or information to the Chatbot, you warrant that you are authorized to upload or share such content with the Chatbot and that you have all the necessary licenses, rights, consents, and permissions and that such user content does not infringe any third party’s intellectual property rights, privacy rights or publicity rights.</p>
                <p>You understand and acknowledge that you are solely responsible for any content you provide and have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to you or any third party for the content shared or uploaded by you. It is your responsibility to obtain any consent required under any applicable laws, regarding the inclusion of any personal information or protected health information (“PHI”) of others in the user content. PHI means protected health information under the Health Insurance Portability and Accountability Act of 1966, and associated regulations, as may be amended from time to time (“HIPAA”).</p>
                <p>Any Content you provide to the Chatbot will be considered non-confidential and non-proprietary. By providing any Content on the Chatbot, you grant us and our affiliates a perpetual, non-exclusive, royalty-free, worldwide license to use, reproduce, modify, perform, display, distribute, make derivative works thereof, and otherwise disclose to third parties any such material for any purpose in compliance with these Terms and the Privacy Statement.</p>
                <h2>4. Restricted Activities</h2>
                <p>The Chatbot is available only to individuals who are at least eighteen (18) years old and possess the legal capacity to enter into these Terms and form a binding agreement under any applicable law. The Chatbot shall not be used in any illegal, unethical, unlawful or unauthorized way, and/or in a manner that infringes these Terms of Use. This, without limitation, may include the following:</p>
                <ol>
                    <li>Using the Chatbot for generating, transmitting, or disseminating information that infringes or violates any of the intellectual property rights or the privacy or proprietary rights of others, is defamatory, unlawful, harassing, abusive, threatening, obscene, hateful, sexist, or racially or ethnically offensive;</li>
                    <li>Using the Chatbot for generating, transmitting or disseminating any unsolicited advertising, promotional materials, junk mail, spam, or any other form of solicitation, commercial or otherwise;</li>
                    <li>Gaining or attempting to gain unauthorized access to any user content, through hacking, password mining or any other means;</li>
                    <li>Using any information obtained from the Chatbot to harass, abuse, or harm another person, or using the Chatbot for uploading, transmitting or disseminating anything that may be harmful to minors;</li>
                    <li>Using the Chatbot for commercial purposes;</li>
                    <li>Transmitting, uploading, attempting to upload or otherwise making available in connection with the Chatbot any virus, worm, trojan horse, time bomb, web bug, spyware, or any other computer code, file, program or other limiting or malicious routine, instruction, or design that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any other computing device;</li>
                    <li>Using any robot, spider, crawler, search or retrieval Chatbotlication, or other manual or automatic device, process or method to access the Chatbot;</li>
                    <li>Attempting to gain access to secured portions of the Chatbot and/or circumventing, attempting to circumvent, disabling, or attempting to disable, or discovering or attempting to discover any security measures (including without limitation encryption algorithms, keys, passwords, and the like) used by Chatbot to protect its software, hardware and/or telecommunications systems.</li>
                    <li>Interfering with, creating an undue burden on, or disrupting the operation of the Chatbot, or servers that host the Chatbot.</li>
                </ol>
                <h2>5. No Medical or Mental Health Advice</h2>
                <p>All content generated/provided by the Chatbot is educational and informational and is created only for general information purposes and doesn’t constitute medical or mental health advice, opinion, diagnosis, treatment, or guarantee.</p>
                <p>CampusWell expressly disclaims any liability concerning any treatment or action by the user in reliance on the Chatbot content. The content and information generated by the Chatbot shall not be used in place of the advice of a doctor, mental health professional/therapist, or other healthcare professionals. Always seek the advice of a doctor, mental health professional/therapist, or other healthcare professional with any questions you may have regarding medical/mental health concerns. You should never disregard medical/mental health advice or delay seeking such advice because of any content or information presented through the Chatbot. Your use of the Chatbot does not constitute or create a doctor-patient, therapist-patient, or other healthcare professional relationship.</p>
                <h2>6. Intellectual Property</h2>
                <p>We retain full IP rights on all content made available to you when interacting with the Chatbot (except for the content provided by you), including without limitation, data, software, text, graphics and images, trademarks, service marks, logos and any other intellectual property. These Terms permit you to use the Chatbot for your personal, non-commercial use only. Nothing in the Terms constitutes a waiver of our intellectual property under any law.</p>
                <h2>7. Disclaimer</h2>
                <p>We have used reasonable efforts in collecting, preparing, and providing quality information and material but don’t guarantee in any manner the accuracy, completeness, or adequacy of the content provided by the Chatbot.</p>
                <p>The Chatbot is offered on an “as is” basis without any warranties either express or implied. We do our best to provide a timely, secure and accurate service. However, the Chatbot’s availability and functionality depend on various factors, such as communication networks and third party providers. You use the Chatbot and our services at your own risk. The Chatbot providers don’t promise that the Chatbot or any content, service, or feature of the Chatbot will be error-free or uninterrupted, or that any defects will be corrected, or that your use of the Chatbot will provide specific results.</p>
                <p>All information provided on the Chatbot is subject to change without notice. We cannot ensure that any files or other data from the Chatbot will be free of viruses or contamination or destructive features.</p>
                <p>We disclaim all warranties, express or implied, including any warranties of accuracy, non-infringement, merchantability, and fitness for a particular purpose. We disclaim any liability for the acts, omissions, and conduct of any third parties in connection with or related to your use of the Chatbot. We expressly disclaim any liability concerning any treatment or action by any person following the information offered or provided within or through the Chatbot or through anyone using the Chatbot. We are not liable for any unfair business practices by third parties.</p>
                <p>You assume total responsibility for your use of the Chatbot. Your sole remedy against CampusWell for dissatisfaction with the Chatbot is to stop using the Chatbot. You agree that your use of or reliance upon any information or content provided by the Chatbot is at your own risk and doesn’t create any warranty.</p>
                <p>This Disclaimer applies to any damages, liability, or injuries caused by a failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence, or any other cause of action.</p>
                <p>We reserve the right to accomplish the following actions at any time, without notice:</p>
                <ol>
                    <li>To Modify, suspend or terminate operation of or access to the Chatbot, or any portion of the Chatbot, for any reason;</li>
                    <li>To modify or change the Chatbot or services, or any portion of the Chatbot or services;</li>
                    <li>To interrupt the operation of the Chatbot or services, or any portion of the Chatbot or services, as necessary to perform routine or non-routine maintenance, error correction, or other changes.</li>
                </ol>
                <h2>8. Limitation of Liability</h2>
                <p>You agree that we will not be liable to you or any other party to the maximum extent permitted by applicable law, for any direct or indirect damages, or for any special, exemplary, punitive, incidental, consequential or other damages (including, but not limited to, lost profits or lost time), whether based on contract, tort, strict liability, or otherwise, which arise out of or are in any way connected with any access to the Chatbot, inability to access the Chatbot or interrupted use of the Chatbot.</p>
                <p>Some jurisdictions (such as Ohio) do not allow the exclusion or limitation of consequential or indirect damages, so the above limitation may not fully apply to the users residing in Ohio.</p>
                <h2>9. Indemnification</h2>
                <p>You agree to defend, indemnify and hold CampusWell harmless from and against any claims, damages, obligations, losses, liabilities, costs, debts, and expenses (including but not limited to attorney fees) arising from:</p>
                <ol>
                    <li>Your use, misuse of, inability to use and/or activities in connection with the Chatbot and/or Content;</li>
                    <li>Your violation of any of these Terms;</li>
                    <li>Your negligence, actions or omissions;</li>
                    <li>Your violation or alleged violation of any law, regulation or ordinance;</li>
                    <li>Your violation or alleged violation of the rights of any third party.</li>
                </ol>
                <p>We reserve the right to assume the exclusive defense and control of any matter which is subject to indemnification by you, which will not excuse your indemnity obligations, and in which event you will fully cooperate with us in asserting any available defense. You agree not to settle any matter subject to indemnification by you without first obtaining our prior express written Approval.</p>
                <h2>10. General</h2>
                <p>These Terms constitute the entire terms of use between you and us and supersede any prior or contemporaneous written or oral agreements or understandings between you and us.</p>
                <p>Any claim relating to the Chatbot will be governed by and interpreted in accordance with the laws of the State of Ohio without reference to principles and laws relating to the conflict of laws.</p>
                <p>You agree that any dispute arising out of, or related to the Chatbot will be brought in the competent courts of the State of Ohio, USA.</p>
                <h1>Privacy Statement</h1>
                <p>CampusWell respects the privacy of the users of My Ohio Wellness Chatbot (hereinafter “the Chatbot”) and is committed to protecting the personal data that we may collect, use, and disclose about you when you use and/or access the Chatbot. All users using and/or accessing the Chatbot shall comply with this Privacy Statement along with the Chatbot’s Terms of Use. By accessing or using any part of the Chatbot, you agree that you have read, understood and consented to be bound by this Privacy Statement and Terms of Use of the Chatbot. If you don't accept these Privacy Statement and our Terms please don't access or use the Chatbot.</p>
                <p>This Privacy Statement is meant to help you understand what information we collect, why we collect it, and how you can manage and delete your information.</p>
                <h2>1. Collection of Your Personal Information</h2>
                <p>You can use and/or access the Chatbot without disclosing any personal information. We may ask you to share personal data in order to provide you with particular services in connection with the Chatbot that may include your name, email address, phone number and zip code, and other personally identifiable information.</p>
                <p>Personal Data may also be collected from various kinds of content, information and materials that you may upload or share with the Chatbot, such as emails, written content, photos, documents and any other input.</p>
                <p>We may also collect personal information concerning your medical condition, including medications, allergies, symptoms, and medication adherence information (“Personal Health Information” or “PHI”).</p>
                <h2>2. Disclosure and Use of Your Personal Information</h2>
                <p>CampusWell respects your privacy and will not rent or sell your personal data to any third party in an identifiable way.</p>
                <p>We may use information collected from your use of the Chatbot for internal business purposes including improving the performance, functionality, and content and performing statistical, design, or operational tasks, such as estimating our audience size, measuring aggregate traffic patterns, and understanding social trends among our users.</p>
                <p>We may disclose personal information as part of any merger, acquisition, debt financing, sale of company assets, or other business transaction.</p>
                <p>We may share or sell aggregated, anonymous information to our advertisers, business partners, and other third parties that includes or is based on information about you. CampusWell takes technical and organizational measures to anonymize your information and to combine it with other users’ information so that it does not directly identify you. CampusWell does not limit the third parties’ use of the aggregated information.</p>
                <p>We may disclose personal information upon your explicit approval or when we believe it is appropriate to:</p>
                <ol>
                    <li>Comply with the law and satisfy any applicable legitimate legal process, subpoena, or governmental request;</li>
                    <li>Enforce or apply this Privacy Statement or our other policies or agreements;</li>
                    <li>Protect rights, property, or personal safety, of ourselves, our users, or the general public;</li>
                    <li>Detect, prevent, or otherwise address fraud, security or technical issues.</li>
                </ol>
                <h2>3. Personal Health Information</h2>
                <p>The Health Insurance Portability and Accountability Act of 1996 (“hipaa”) requires that all protected Health Information (PHI) and other individually identifiable health information used or disclosed by us in any form are kept properly confidential.</p>
                <p>PHI is information about you, including personal information:</p>
                <ol>
                    <li>That is created or received by a healthcare provider, health plan, public health authority, employer, life insurer, school or university, or healthcare clearinghouse;</li>
                    <li>That may identify you or could reasonably be used to identify you and that relates to your past, present or future physical or mental health or condition and related health care services;</li>
                    <li>Transmitted or maintained in any form or medium, including electronically.</li>
                </ol>
                <p>The Personal Health Information you upload or share with the Chatbot may be disclosed only with your prior approval or without your prior approval in the following situations:</p>
                <ol>
                    <li>We may disclose your PHI for law enforcement purposes and as required by state or federal law;</li>
                    <li>To comply with an order in a legal or administrative proceeding, or in response to a subpoena discovery request or other lawful process, but only if efforts have been made, by us or the requesting party, to contact you about the request or to obtain an order from a court or administrative body to protect the requested PHI;</li>
                    <li>If it is necessary to avoid a serious threat to the health and safety of you or the public;</li>
                    <li>To a health oversight agency, such as Health and Human Service (HHS) for activities authorized by law. These activities include audits; civil, administrative, or criminal investigations or proceedings; inspections; licensure or disciplinary actions; or other activities necessary for oversight of the health care system, government programs and compliance with civil rights laws. we may also be required to disclose PHI to authorized federal officials for the conduct of intelligence or other national security activities.</li>
                    <li>To comply with applicable laws.</li>
                </ol>
                <h2>4. Security</h2>
                <p>CampusWell takes precautions to protect your information. We take appropriate measures to maintain the security and integrity of our Chatbot and prevent unauthorized access to or use of it through generally accepted industry standard technologies and internal procedures. Please note, however, that there are inherent risks in the transmission of information over the Internet or other methods of electronic storage and we cannot guarantee that unauthorized access or use will never occur.</p>
                <p>We will comply with applicable law in the event of any breach of the security, confidentiality, or integrity of your personal data and will inform you of such breach if required by applicable law. To the extent that we implement the required security measures under applicable law, we shall not be responsible or liable for unauthorized access, hacking, or other security intrusions or failure to store or the theft, deletion, corruption, destruction, damage, or loss of any data or information included in the personal data.</p>
                <h2>5. Controlling Your Personal Information</h2>
                <p>We will retain the personal data for as long as we believe that it is accurate and can be relied upon. Personal Data that is no longer required for the purpose for which it was initially collected, will be deleted unless we have a valid justification to retain it that is permitted under applicable law, such as to resolve disputes or comply with our legal obligations.</p>
                <p>You have the right to submit a complaint or to object to the collection and use of your information by CampusWell at any time, or to require Campuswell to review, transmit, change, or delete the information that we have collected and retained. We will fulfill your request to exercise any of these rights within a reasonable timeframe, not exceeding 30 days of receiving a complete request.</p>
                <h2>6. Updates to the Privacy Statement</h2>
                <p>We reserve the right to amend this Privacy Statement at any time. As our Chatbot continues to develop, we may add new services and features to it. In the event that these additions affect our Privacy Statement, this Privacy Statement will be updated appropriately. By continuing to use the Chatbot following the effective date of the updated Privacy Statement, you are agreeing to be bound by the terms of such updated Privacy Statement.</p>
            </div>
            <div className={styles.WrapperActions}>
                <button className={styles.cancel} onClick={onCancel}>I Disagree</button>
                <button className={styles.approve} onClick={onApprove}>I Agree</button>
            </div>
        </div>
    );
}

export default TermsAndConditionsDialog;
