import axiosClient from '../axiosClient';

const getConfig = async () => {
    try {
        const response = await axiosClient.get(`/config`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const getThreadUuid = async () => {
    try {
        const response = await axiosClient.get(`/thread`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const sendMessage = async (payload, threadUuid) => {
    try {
        const response = await axiosClient.put(`/thread/${threadUuid}/message`, payload);

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const chatService = {
    getConfig,
    getThreadUuid,
    sendMessage,
};
