import React from 'react';
import styles from './WrapperPrompts.module.scss';

function WrapperPrompts({prompts = [], onSelectPrompt}) {
    return (
        <div
            className={styles.WrapperPrompt}
        >
            {prompts.map((promp, index) => (
                <div onClick={() => onSelectPrompt({...promp, index})} key={index} className={styles.PromptItem}>
                    <div className={styles.title}>{promp.heading}</div>
                    <div className={styles.text}>{promp.subheading}</div>
                </div>
            ))}
        </div>
    );
}

export default WrapperPrompts;
