import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import OpenChatButton from './components/OpenChatButton/OpenChatButton';
import WrapperChat from './components/WrapperChat/WrapperChat';

import { chatService } from './features/chat/chatService';
import { dataLayerService } from './features/dataLayerService';

import './app.css';

function App() {
  const [showDialog, setShowDialog] = useState('closed'); // closed, minimized, open
  const [prompts, setPrompts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const {presets, ChatInitialState, GoogleTagManagerCode} = await chatService.getConfig();
      setPrompts(presets);

      if (GoogleTagManagerCode) {
        dataLayerService.createAdditionalGMTScript(GoogleTagManagerCode);
      }

      if (ChatInitialState === 'opened') {
        openDialog();
      }
    }

    fetch();

    return () => {
      dataLayerService.onChatEvent('cancel');
    };
  }, []);

  const openDialog = () => {
    window.parent.postMessage({ action: 'openCwChatBot' }, '*');
    setShowDialog('open');
    dataLayerService.onChatEvent('open');
  }

  const cancelDialog = (mode = 'closed') => {
    window.parent.postMessage({ action: 'cancelCwChatBot' }, '*');
    setShowDialog(mode);
    dataLayerService.onChatEvent('cancel');
  }

  const chatClass = showDialog === 'minimized' ? 'minimized' : showDialog === 'open' ? 'open' : 'closed';

  return (
    <>
      <CSSTransition
        in={showDialog !== 'closed'}
        unmountOnExit={showDialog === 'closed'}
        timeout={0}
      >
        <div className={`chat-container ${chatClass}`}>
          <WrapperChat prompts={prompts} onCancel={cancelDialog} />
        </div>
      </CSSTransition>
      {(showDialog === 'closed' || showDialog === 'minimized') && (
        <OpenChatButton mode={showDialog} onClick={openDialog} />
      )}
    </>
  );
}

export default App;
