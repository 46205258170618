import React from 'react';
import styles from './ConfirmDialog.module.scss';

function ConfirmDialog({text, onCancel, onApprove}) {
    return (
        <div
            className={styles.ConfirmDialog}
        >
            {text}
            <div className={styles.WrapperActions}>
                <button className={styles.cancel} onClick={onCancel}>No</button>
                <button className={styles.approve} onClick={onApprove}>Yes</button>
            </div>
        </div>
    );
}

export default ConfirmDialog;
