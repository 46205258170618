import React from 'react';
import styles from './OpenChatButton.module.scss';

function OpenChatButton({onClick, mode}) {
    return (
        <button
            className={styles.OpenChatButton}
            type="button"
            onClick={onClick}
        >
            {mode === 'minimized' && <div className={styles.live}></div>}
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0067 0C11.6245 0 13.2361 0.399066 14.668 1.15403C19.5432 3.72638 21.4181 9.78549 18.8475 14.6624C17.0942 17.9879 13.642 20 9.9953 20C9.28286 20 8.56337 19.9234 7.84741 19.7648C7.44408 19.6749 7.18957 19.275 7.27851 18.8715C7.36746 18.468 7.76639 18.2152 8.17237 18.3024C11.8966 19.1305 15.7415 17.3431 17.523 13.9638C19.7088 9.81809 18.1148 4.66546 13.9696 2.47897C12.7526 1.83676 11.3823 1.4976 10.0059 1.4976H9.99618C5.31028 1.4976 1.4971 5.31119 1.4971 9.99868C1.49534 11.3641 1.82822 12.7234 2.45964 13.9312L2.65162 14.3065C2.88675 14.747 2.93871 15.294 2.78636 15.7891C2.57588 16.3441 2.40064 16.8841 2.25621 17.4215C2.81806 17.2523 3.50408 17.003 3.99988 16.8224L4.20331 16.7493C4.58815 16.6057 5.02055 16.8092 5.16145 17.1986C5.30235 17.5871 5.10069 18.017 4.71232 18.1571L4.51154 18.2302L4.47736 18.2426L4.47733 18.2426C3.73908 18.5101 2.74506 18.8703 2.0704 19.0213C2.01227 19.0336 1.94975 19.0415 1.89251 19.0398C1.43281 19.0398 1.15277 18.8539 0.998654 18.6971C0.761761 18.4566 0.649039 18.1192 0.662249 17.6946C0.66401 17.6461 0.670175 17.5959 0.681623 17.5475C0.859513 16.7899 1.09112 16.034 1.37117 15.3019C1.39142 15.2306 1.37909 15.1011 1.32185 14.9927L1.12899 14.6183C0.39013 13.2035 -0.00175537 11.6029 5.91087e-06 9.9978C5.91087e-06 4.48487 4.48424 0 9.9953 0H10.0067ZM4.40348 9.99912C4.40348 9.34282 4.93627 8.80985 5.59235 8.80985C6.24843 8.80985 6.78122 9.34282 6.78122 9.99912C6.78122 10.6545 6.24843 11.1875 5.59235 11.1875C4.93627 11.1875 4.40348 10.6545 4.40348 9.99912ZM11.1845 9.99877C11.1845 9.34335 10.6517 8.8095 9.99565 8.8095C9.33957 8.8095 8.80678 9.34335 8.80678 9.99877C8.80678 10.6542 9.33957 11.188 9.99565 11.188C10.6517 11.188 11.1845 10.6542 11.1845 9.99877ZM14.3989 8.8095C15.0549 8.8095 15.5877 9.34335 15.5877 9.99877C15.5877 10.6542 15.0549 11.188 14.3989 11.188C13.7428 11.188 13.21 10.6542 13.21 9.99877C13.21 9.34335 13.7428 8.8095 14.3989 8.8095Z" fill="#fff"/>
            </svg>
        </button>
    );
}

export default OpenChatButton;
