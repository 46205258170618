import React from 'react';
import styles from './HeaderChat.module.scss';

function HeaderChat({text, isConnected, onMinimized, onCancel}) {
    return (
        <div
            className={styles.HeaderChat}
        >
            <span className={isConnected ? styles.online : styles.offline}>{text}</span>

            <div className={styles.WrapperActions}>
                <button onClick={onMinimized}>
                    <div className={styles.minimized}></div>
                </button>
                <button onClick={onCancel}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.45832 4.45833L15.5417 15.5417M4.45832 15.5417L15.5417 4.45833" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default HeaderChat;
