import React, { useRef, useEffect } from 'react';
import styles from './MessageChat.module.scss';
import { dataLayerService } from "../../features/dataLayerService";

function useLinkClickHandler(containerRef, callback) {
  useEffect(() => {
    const container = containerRef.current;
    const handleClick = event => {
      if (event.target.tagName === 'A') {
        callback(event.target);
      }
    };

    container.addEventListener('click', handleClick);

    return () => {
      container.removeEventListener('click', handleClick);
    };
  }, [containerRef, callback]);
}

function MessageChat({text, time, type = 'bot' || 'user'}) {
    const containerRef = useRef(null);

    useLinkClickHandler(containerRef, (link) => {
      dataLayerService.onChatEvent('link_click', {
        chatLinkType: link.hasAttribute('data-link-type') ? link.getAttribute('data-link-type') : '',
        chatSlug: link.hasAttribute('data-slug') ? link.getAttribute('data-slug') : '',
        chatVideoId: link.hasAttribute('data-video-id') ? link.getAttribute('data-video-id') : '',
        chatUrl: link.href,
      });
    });

    return (
        <div
            className={[
                styles.MessageChat,
                type === 'user' && styles.user,
                type === 'bot' && styles.bot,
            ].join(' ')}
        >
            {type === 'bot' &&
                <div className={styles.icon}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.7587 5H15.2413C16.0463 4.99999 16.7106 4.99998 17.2518 5.04419C17.8139 5.09012 18.3306 5.18868 18.816 5.43597C19.5686 5.81947 20.1805 6.43139 20.564 7.18404C20.8113 7.66937 20.9099 8.18608 20.9558 8.74817C21 9.28936 21 9.95372 21 10.7587V15.2413C21 16.0463 21 16.7106 20.9558 17.2518C20.9099 17.8139 20.8113 18.3306 20.564 18.816C20.1805 19.5686 19.5686 20.1805 18.816 20.564C18.3306 20.8113 17.8139 20.9099 17.2518 20.9558C16.7106 21 16.0463 21 15.2413 21H8.75873C7.95374 21 7.28938 21 6.74817 20.9558C6.18608 20.9099 5.66937 20.8113 5.18404 20.564C4.43139 20.1805 3.81947 19.5686 3.43597 18.816C3.18868 18.3306 3.09012 17.8139 3.04419 17.2518C2.99998 16.7106 2.99999 16.0463 3 15.2413V10.7587C2.99999 9.95373 2.99998 9.28937 3.04419 8.74817C3.09012 8.18608 3.18868 7.66937 3.43597 7.18404C3.81947 6.43139 4.43139 5.81947 5.18404 5.43597C5.66937 5.18868 6.18608 5.09012 6.74817 5.04419C7.28937 4.99998 7.95373 4.99999 8.7587 5ZM6.91104 7.03755C6.47262 7.07337 6.24842 7.1383 6.09202 7.21799C5.7157 7.40973 5.40973 7.71569 5.21799 8.09202C5.1383 8.24842 5.07337 8.47262 5.03755 8.91104C5.00078 9.36113 5 9.94342 5 10.8V15.2C5 16.0566 5.00078 16.6389 5.03755 17.089C5.07337 17.5274 5.1383 17.7516 5.21799 17.908C5.40973 18.2843 5.7157 18.5903 6.09202 18.782C6.24842 18.8617 6.47262 18.9266 6.91104 18.9624C7.36113 18.9992 7.94342 19 8.8 19H15.2C16.0566 19 16.6389 18.9992 17.089 18.9624C17.5274 18.9266 17.7516 18.8617 17.908 18.782C18.2843 18.5903 18.5903 18.2843 18.782 17.908C18.8617 17.7516 18.9266 17.5274 18.9624 17.089C18.9992 16.6389 19 16.0566 19 15.2V10.8C19 9.94342 18.9992 9.36113 18.9624 8.91104C18.9266 8.47262 18.8617 8.24842 18.782 8.09202C18.5903 7.71569 18.2843 7.40973 17.908 7.21799C17.7516 7.1383 17.5274 7.07337 17.089 7.03755C16.6389 7.00078 16.0566 7 15.2 7H8.8C7.94342 7 7.36113 7.00078 6.91104 7.03755Z" fill="black"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 2.43L10 3.00006L8 2.99994L8.00009 1.59801L8.27826 1.3079C8.52078 1.05498 8.88254 0.687155 9.51489 0.412789C10.1265 0.147442 10.9151 0 12 0C13.085 0 13.8736 0.147454 14.4852 0.412808C15.1175 0.687178 15.4793 1.055 15.7218 1.30788L16 1.59802V3H14V2.42998C13.9115 2.36092 13.8144 2.30192 13.6891 2.24754C13.4093 2.12611 12.9151 2 12 2C11.085 2 10.5908 2.12611 10.3109 2.24754C10.1856 2.30192 10.0886 2.36092 10 2.43Z" fill="black"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.0005 17C10.8097 17.0006 9.59337 16.695 8.4 15.8L9.6 14.2C10.4066 14.805 11.1886 15.0004 11.9995 15C12.8103 14.9996 13.594 14.8045 14.4 14.2L15.6 15.8C14.406 16.6955 13.1879 16.9994 12.0005 17Z" fill="black"/>
                        <path d="M7 11.5C7 10.6716 7.67157 10 8.5 10C9.32843 10 10 10.6716 10 11.5C10 12.3284 9.32843 13 8.5 13C7.67157 13 7 12.3284 7 11.5Z" fill="black"/>
                        <path d="M14 11.5C14 10.6716 14.6716 10 15.5 10C16.3284 10 17 10.6716 17 11.5C17 12.3284 16.3284 13 15.5 13C14.6716 13 14 12.3284 14 11.5Z" fill="black"/>
                    </svg>
                </div>
            }
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} ref={containerRef}/>
            {type === 'user' &&
                <div className={styles.icon}>
                    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.84455 20.6618C4.15273 20.6618 1 20.0873 1 17.7865C1 15.4858 4.13273 13.3618 7.84455 13.3618C11.5364 13.3618 14.6891 15.4652 14.6891 17.766C14.6891 20.0658 11.5564 20.6618 7.84455 20.6618Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.83725 10.1735C10.26 10.1735 12.2236 8.2099 12.2236 5.78718C12.2236 3.36445 10.26 1.3999 7.83725 1.3999C5.41452 1.3999 3.44998 3.36445 3.44998 5.78718C3.4418 8.20172 5.3918 10.1654 7.80634 10.1735C7.81725 10.1735 7.82725 10.1735 7.83725 10.1735Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            }
            {time && <div className={styles.time}>{time}</div>}
        </div>
    );
}

export default MessageChat;
